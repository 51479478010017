<template>
  <section class="quotation-document" id="quotation-document">
    <div class="page-header m-0">
      <nav aria-label="breadcrumb">
        <ol class="align-items-center breadcrumb d-flex flex-row">
          <li class="breadcrumb-item">
            <div class="d-flex flex-row align-items-center">
              <i class="mdi mdi-arrange-send-to-back mdi-18px mr-2"></i>
              ขายสินค้า
            </div>
          </li>
          <li class="breadcrumb-item active" aria-current="page"> ใบเสนอราคา</li>
        </ol>
      </nav>
    </div>
    <div class="row">
      <div class="col-lg-12 grid-margin stretch-card">
        <div class="card">
          <div class="card-body shadow-sm">
            <div class="d-flex flex-row justify-content-between">
              <div class="d-flex flex-row align-items-center mb-4">
                <button 
                    type="button"
                    class="btn btn-info btn-xs font-weight-light text-white form-control-btn">
                    <i class="mdi mdi-file-check btn-icon-prepend"></i>
                    Approved
                </button>
                <button 
                    type="button"
                    class="btn btn-warning btn-xs font-weight-light text-white form-control-btn">
                    <i class="mdi mdi-file-check btn-icon-prepend"></i>
                    Negotiate
                </button>
                <button 
                    type="button"
                    class="btn btn-danger btn-xs font-weight-light text-white form-control-btn">
                    <i class="mdi mdi-file-check btn-icon-prepend"></i>
                    Reject
                </button>
              </div>
              <button 
                type="button"
                @click="onQuotationClose"
                class="bg-transparent border-0 rounded-pill px-4 font-weight-light text-google text-sm mr-2">
                กลับ
              </button>
            </div>
            <div class="m-auto overflow-auto row w-100 lineheight-normal">
              <QuotationDocument></QuotationDocument>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import QuotationDocument from '@/components/dialogs/quotation'
export default {
  components: {
    QuotationDocument
  },
  methods: {
    onQuotationClose() {
      this.$emit('tabIndex', { 
        index: 0
      })
    }
  },
}
</script>

<style>
    #quotation-document .btn-info, #quotation-document .btn-danger, #quotation-document .btn-warning {
        font-size: 0.875rem !important;
    }
</style>