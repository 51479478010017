import SalesList from './sales-list'
import Quotation from './quotation'
import PurchaseOrderDocument from './document/document-info/document-info-purchase-order.vue'
import InvoiceDocument from './document/document-info/document-info-invoice.vue'
import RefundsDocument from './document/document-info/document-info-refund.vue'
import QuotationDocument from './document/document-info/document-info-quotation.vue'
import InvoiceCreate from './document/document-create/document-create-invoice'
import RefundsCreate from './document/document-create/document-create-refund'
import PurchaseOrderCreate from './document/document-create/document-create-purchase-order'

export default {
  name: 'sales',
  components: {
    SalesList,
    Quotation,
    PurchaseOrderDocument,
    QuotationDocument,
    InvoiceDocument,
    RefundsDocument,
    InvoiceCreate,
    RefundsCreate,
    PurchaseOrderCreate
  },
  data() {
    return {
      tabIndex: 0
    }
  },
  methods: {
    onTabIndexChange(event) {
      this.tabIndex = event.index
    }
  }
}
