<template>
  <section class="purchase-order-document" id="purchase-order-document">
    <div class="page-header m-0">
      <nav aria-label="breadcrumb">
        <ol class="align-items-center breadcrumb d-flex flex-row">
          <li class="breadcrumb-item">
            <div class="d-flex flex-row align-items-center">
              <i class="mdi mdi-arrange-send-to-back mdi-18px mr-2"></i>
              ขายสินค้า
            </div>
          </li>
          <li class="breadcrumb-item active" aria-current="page"> ใบสั่งซื้อ</li>
        </ol>
      </nav>
    </div>
    <div class="row">
      <div class="col-lg-12 grid-margin stretch-card">
        <div class="card">
          <div class="card-body shadow-sm">
            <div class="d-flex flex-row justify-content-between">
              <div class="d-flex flex-row align-items-center mb-4">
              </div>
              <button 
                type="button"
                @click="onPurchaseOrderClose"
                class="bg-transparent border-0 rounded-pill px-4 font-weight-light text-google text-sm mr-2">
                กลับ
              </button>
            </div>
            <div class="m-auto overflow-auto row w-100 lineheight-normal">
              <PurchaseOrderDocument></PurchaseOrderDocument>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import PurchaseOrderDocument from '@/components/dialogs/purchase-order'
export default {
  components: {
    PurchaseOrderDocument
  },
  methods: {
    onPurchaseOrderClose() {
      this.$emit('tabIndex', { 
        index: 0
      })
    }
  },
}
</script>

<style>
  #purchase-order-document .btn-info, #purchase-order-document .btn-danger, #purchase-order-document .btn-warning {
    font-size: 0.875rem !important;
  }
</style>